<template>
  <t-page>
    <template #header>
      <h3>{{ $t('fields.organizations_progress') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import IndexTable from '~/pages/shared/organizations-progress/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'tenantmember',

  data: () => ({
    query: () => new Organization(),

    filters: ['search'],

    fields: [
      'name',
      'usage_type',
      'employees_count',
      'profiles_count',
      'email_templates_count',
      'invitations_count',
      'alerts_count',
      'sourced_applicants_count',
      'pinged_applicants_count',
    ],
  }),
});
</script>
